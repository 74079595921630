import { useCallback, useDebugValue, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import eventSelectorService from "redux/selector/events";

import { useBrand } from "hooks/BrandManager/BrandManager.hooks";
import { findRecipientImageInBadgeProps, findRecipientImageInPolotnoProps } from "utils/misc";

import usePushSnack from "../../../../hooks/SnackbarManager";
import useTranslation from "../../../../i18n/hooks/useTranslation";
import { axios } from "../../../../lib/axios";
import badgeActionService from "../../../../redux/actions/badge";
import sideActionsService from "../../../../redux/actions/sideActions";
import validationActionService from "../../../../redux/actions/validation";
import { useGetOrgaSlugs } from "../../../../redux/hooks/validation";
import certificatesSelectorService from "../../../../redux/selector/certificates";
import controlSelectorService from "../../../../redux/selector/control";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { EAuthButton, EAuthType, EBadgeType } from "../../../../types/enums";
import { AnyObject } from "../../../../types/global";
import cleanupLocalStorageFromUnusedAccessTokens from "../../../../utils/cleanupLocalStorageFromUnusedAccessToken";
import { useTwitterRequestToken } from "../../../certificateDownload/api/shareApi";
import { CertificateValidatorApiRoutes } from "../../api/routes";

import { EAuthFormTokenHandling } from "./authForm.enums";
import { otpTokenHandling } from "./authForm.helpers";

/**
 * Helper hook to post authentification type
 * @returns {Function} Function to post authentification type information to backend
 */
export const usePostLogAuthType = () => {
    const { orga_slug, certificate_slug } = useGetOrgaSlugs();
    return ({ token, authType }: { token: string; authType: EAuthType }) => {
        return axios.post(
            CertificateValidatorApiRoutes.postLogAuth(orga_slug || "", certificate_slug || "", token, authType)
        );
    };
};

/**
 * Helper hook for authentification with social media button
 * @returns {Function} OnClick function to open social media authentification login pages
 */
export const useValidatorSubmitForm = () => {
    const requestTwitterToken = useTwitterRequestToken();
    const { certificate_slug, orga_slug } = useGetOrgaSlugs();
    const BrandManager = useBrand();

    const logAuthType = usePostLogAuthType();

    const token = useAppSelector(controlSelectorService.selectToken);
    const selectedCertificate = useAppSelector(certificatesSelectorService.selectedCertificate);

    /**
     *
     * @param event Submit auth for social platforms
     * @param extraFlags Extra flags for url state
     */
    const submitForm = (
        event: AnyObject | string,
        tokenType: "linkedin" | "facebook" | "email" | "twitter" | null,
        extraFlags?: string[]
    ) => {
        const authType = typeof event === "string" ? event : event.target.id;
        const extras = extraFlags && extraFlags?.length > 0 ? `,${extraFlags?.join(",")}` : "";

        if (tokenType === "linkedin" || tokenType === "facebook") cleanupLocalStorageFromUnusedAccessTokens(tokenType);
        localStorage.setItem("last_viewed_certificate", selectedCertificate.certificate_id);

        // If it is verification with social-media place a temporary local storage item with the token inside.
        if (tokenType && tokenType !== "email") localStorage.setItem("social_media_verification_token", token);

        // Log auth type
        logAuthType({ token, authType });
        switch (authType) {
            case EAuthType.LINKEDIN:
                window.open(
                    `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${BrandManager.getLinkedinAppId()}&redirect_uri=${
                        window.location.origin
                    }&state=linkedin,${orga_slug},${certificate_slug},badge${extras}&scope=r_liteprofile%20w_member_social`,
                    "_system"
                );
                break;
            case EAuthType.FACEBOOK:
                window.open(
                    `https://www.facebook.com/v8.0/dialog/oauth?client_id=${BrandManager.getFacebookAppId()}&redirect_uri=${
                        window.location.origin
                    }&state=facebook,${orga_slug},${certificate_slug},badge${extras}`,
                    "_system"
                );
                break;
            case EAuthType.TWITTER:
                requestTwitterToken();
                break;
            default:
                break;
        }
    };

    return { submitForm };
};

/**
 * Helper hook for verification
 * @returns Helper functions for verification and selected verification method
 */
export const useValidationMethod = () => {
    const pushSnack = usePushSnack();
    const navigate = useNavigate();
    const t = useTranslation();

    const { orga_slug, certificate_slug } = useGetOrgaSlugs();
    const dispatch = useAppDispatch();

    const token = useAppSelector(controlSelectorService.selectToken);
    const verifyMethod = useAppSelector(controlSelectorService.selectVerificationMethod);
    const newFoundCertificates = useAppSelector(controlSelectorService.selectNewFoundCertificates);

    const [isTokenWasSent, setIsTokenWasSent] = useState(false);

    const [showSocialMediaProfilePictureUpload, setShowSocialMediaProfilePictureUpload] = useState(false);

    const { data: certificates, loadingState: isCertificatesLoaded } = useAppSelector(
        certificatesSelectorService.selectCertificates
    );
    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);

    const isSocialMediaEnabled = eventDetails?.event_social_media_mode_enabled;

    const paramOtp = new URLSearchParams(window.location.search).get("otp_code");

    const multipleCertificates = certificates.length > 1;

    const navigateToBadgeRoute = () => {
        navigate(`/${orga_slug}/${certificate_slug}/badge`);
    };

    const delayEnableResendOTP = () => {
        // Disable the resend button for 60 seconds
        const resendDelay = setTimeout(() => {
            setIsTokenWasSent(false);
            clearTimeout(resendDelay);
        }, 60000);
    };

    /**
     * Checks if the provided certificate props have a profile picture.
     * Do not redirect to /badge if the certificate props have a profile picture.
     * The user then can attach a profile picture via social media.
     * This will change the verification type to social media.
     * Additional the user can continue without upload a profile picture from social media.
     */
    const propsHaveProfilePicture = useMemo(() => {
        if (certificates.length < 1) return false;
        const propsType = certificates[0].badge_props_type;
        if (!propsType) throw Error("No prop type provided");
        if (propsType === EBadgeType.BADGE)
            return findRecipientImageInBadgeProps(certificates[0].badge_props as VbDesigner.IBadgeProps) !== undefined;
        if (propsType === EBadgeType.POLOTNO)
            return (
                findRecipientImageInPolotnoProps(
                    certificates[0].badge_props as PolotnoDesigner.PolotnoBadgePropsData
                ) !== undefined
            );
        throw Error("Invalid badge props type");
    }, [certificates]);

    const onEmailClick = async (searchParamTokenResend = false) => {
        setIsTokenWasSent(true);
        dispatch(
            validationActionService.sendOtpToken({
                config: { token },
                successCallback: (email) => {
                    if (!searchParamTokenResend)
                        pushSnack({
                            title: t("certificate.authForm.form.email_verification.messages.codeSent"),
                            body: t("certificate.authForm.form.email_verification.messages.codeSentToEmail", {
                                email,
                            } as any),
                            type: "success",
                            autoHideDuration: 10000,
                        });
                    delayEnableResendOTP();
                },
                failedCallback: () => {
                    pushSnack({
                        title: t("certificate.authForm.form.email_verification.messages.errorContactClientTitle"),
                        body: t("certificate.authForm.form.email_verification.messages.errorContactClient"),
                        type: "error",
                        autoHideDuration: 10000,
                    });

                    setIsTokenWasSent(false);
                },
            })
        );
    };

    const changeVerifyMethod = (method: EAuthButton, byEmailTimestamp = false) => {
        dispatch(sideActionsService.setVerifyMethod(method));
        // Send email directly when changing to Email auth type
        if (method === EAuthButton.EMAIL && !byEmailTimestamp) onEmailClick();
    };

    const continueWithEMailVerified = useCallback(() => {
        axios
            .post(
                `/api/public/participant?orga_slug=${orga_slug}&event_slug=${certificate_slug}&token=${token}&stats_auth_type=email`
            )
            .catch((e) => console.error(e));
        pushSnack({
            title: t("certificate.authForm.form.email_verification.messages.verified"),
            body: t("certificate.authForm.form.email_verification.messages.verifiedMessage"),
            type: "success",
            autoHideDuration: 10000,
        });
        if (newFoundCertificates.length > 0) dispatch(badgeActionService.chunkBadgeBaking());
        dispatch(sideActionsService.setLoadingState({ type: "validateOtpToken", state: false }));
        navigateToBadgeRoute();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certificate_slug, newFoundCertificates.length, orga_slug, pushSnack, token]);

    const hasEventAuthMethodSocialMedia =
        eventDetails?.event_auth_methods?.includes("facebook") ||
        eventDetails?.event_auth_methods?.includes("linkedin");

    const onVerifyCode = async (otp: string, searchParamOtp = false) => {
        cleanupLocalStorageFromUnusedAccessTokens("email");
        if (!otp) {
            pushSnack({
                body: t("certificate.authForm.form.email_verification.messages.missingCode"),
                type: "error",
                autoHideDuration: 10000,
            });
            return;
        }
        dispatch(sideActionsService.setLoadingState({ type: "validateOtpToken", state: true }));
        dispatch(
            validationActionService.validateOtpToken({
                config: {
                    otp,
                    type: "participant",
                    token,
                },
                successCallback: async () => {
                    // Remove the e-mail-token-sent timestamp and last_used_token from local storage
                    otpTokenHandling().remove(EAuthFormTokenHandling.BOTH);
                    if (
                        paramOtp &&
                        propsHaveProfilePicture &&
                        !multipleCertificates &&
                        isSocialMediaEnabled &&
                        hasEventAuthMethodSocialMedia
                    ) {
                        setShowSocialMediaProfilePictureUpload(true);
                        dispatch(sideActionsService.setLoadingState({ type: "validateOtpToken", state: false }));
                        return;
                    }
                    continueWithEMailVerified();
                },
                failedCallback: () => {
                    dispatch(sideActionsService.setLoadingState({ type: "validateOtpToken", state: false }));
                    if (!searchParamOtp)
                        pushSnack({
                            body: t("certificate.authForm.form.email_verification.messages.wrongCode"),
                            type: "error",
                            autoHideDuration: 10000,
                        });
                },
            })
        );
    };

    const onSocialMediaClick = () => {
        // Remove the e-mail-token-sent timestamp and last_used_token from local storage
        otpTokenHandling().remove(EAuthFormTokenHandling.BOTH);
        dispatch(sideActionsService.setVerifyMethod(EAuthButton.SOCIAL_MEDIA));
    };

    useEffect(() => {
        if (isCertificatesLoaded === "fulfilled") {
            if (
                eventDetails?.event_auth_methods?.length === 0 ||
                (!eventDetails?.event_auth_methods?.includes("email") && multipleCertificates)
            ) {
                if (newFoundCertificates.length > 0) dispatch(badgeActionService.chunkBadgeBaking());
                navigateToBadgeRoute();
            } else if (paramOtp && token) onVerifyCode(paramOtp, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, paramOtp, isCertificatesLoaded, eventDetails?.event_auth_methods]);

    const returnValues = {
        verifyMethod,
        changeVerifyMethod,
        onEmailClick,
        onSocialMediaClick,
        onVerifyCode,
        certificates,
        multipleCertificates,
        showSocialMediaProfilePictureUpload,
        continueWithEMailVerified,
        isTokenWasSent,
        navigateToBadgeRoute,
    };

    useDebugValue(returnValues);

    return returnValues;
};
