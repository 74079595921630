import { sanitize } from "sanitize-filename-ts";

/**
 * Sanitize combination of filename strings inclusive extension.
 *
 * Replace space with "_"
 * @example sanitzeFileName("hello world", {suffix: "?jkdfj/", fileType: "pdf"}) => "hello_world_jkdfj.pdf"
 * @argument {string} fileName (Required) Pure filename
 * @argument {string} prefix (Optional) Prefix of the filename
 * @argument {string} suffix (Optional) Suffix of the filename
 * @argument {string} fileType (Optional) File type of the filename without dot
 * @param {string} combinedFileName Combined file name
 * @returns {string} Sanitized file name
 */
export const sanitizeFileName = (
    fileName: string,
    options: { prefix?: string; suffix?: string; fileType?: string }
) => {
    let combinedFileName = fileName;
    if (options.prefix) combinedFileName = `${options.prefix}-${combinedFileName}`;
    if (options.suffix) combinedFileName = `${combinedFileName}-${options.suffix}`;
    combinedFileName = sanitize(combinedFileName.replaceAll(" ", "_"));
    if (options.fileType) combinedFileName = `${combinedFileName}.${options.fileType}`;
    return combinedFileName;
};

/**
 * Sanitizes a string for use in filenames by:
 * 1. Replacing all occurrences of "@" with an underscore.
 * 2. Replacing all occurrences of "." with an underscore.
 * 3. Replacing any other disallowed characters (anything except letters, digits, dash, underscore, and common umlauts) with a dash.
 *
 * This makes "hello@world.pdf" become "hello_world-pdf" and "my@crazy!ßfile.name" become "my_crazy-ßfile-name".
 *
 * @param input - The string to sanitize
 * @returns The sanitized filename string
 */
export const sanitizeFileNameString = (input: string): string =>
    input
        .replace(/@/g, "_") // convert "@" to underscore
        .replace(/\./g, "-") // convert "." to underscore
        .replace(/[^a-zA-Z0-9\-_äöüÄÖÜß]/g, "-"); // convert any other forbidden character to dash

interface BaseRecipientData {
    recipient_name: string;
    certificate_id: string;
}
/**
 * Prepares a valid filename for the downloaded certificates/badges. The filename consists of the first 20 characters of the recipient's full name and the first 4 characters of the certificate ID. Also adds the file format extension if provided.
 * @param recipientData - The recipient data.
 * @param format - The format of the file. Optional.
 * @returns The generated filename.
 */
export const generateCertificateFileName = <T extends BaseRecipientData>(
    recipientData: T,
    format?: "png" | "pdf" | "zip"
): string => {
    const { recipient_name, certificate_id } = recipientData;
    const truncatedName = recipient_name.substring(0, 20);
    const truncatedId = certificate_id.substring(0, 4);
    const cleanedName = sanitizeFileNameString(truncatedName);
    const filename = format ? `${cleanedName}-${truncatedId}.${format}` : `${cleanedName}-${truncatedId}`;
    return sanitize(filename);
};
