import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { selectStore } from "../store";

const selectControl = createDraftSafeSelector(selectStore, (store) => store.control);

const selectSlugs = createDraftSafeSelector(selectControl, (control) => control.slugs);

const selectToken = createDraftSafeSelector(selectControl, (control) => control.token);

const selectUserEmail = createDraftSafeSelector(selectControl, (control) => control.userEmail);

const selectUserCredentialNames = createDraftSafeSelector(selectControl, (control) => control.credentialNames);

const selectTokenValidation = createDraftSafeSelector(selectControl, (control) => control.tokenValidation);

const selectSocialMediaProfiles = createDraftSafeSelector(selectControl, (control) => control.socialMediaProfiles);

const selectUserFlow = createDraftSafeSelector(selectControl, (control) => control.userFlow);

const selectDownload = createDraftSafeSelector(selectControl, (control) => control.download);

const selectDownloadFormat = createDraftSafeSelector(selectDownload, (download) => download.downloadFormat);

const selectVerificationMethod = createDraftSafeSelector(selectUserFlow, (userFlow) => userFlow.verificationMethod);

const selectFlowLoading = createDraftSafeSelector(selectControl, (control) => control.loading);

const selectBadgeIsParsing = createDraftSafeSelector(selectControl, (control) => control.badgeIsParsing);

const selectSocialMediaShareState = createDraftSafeSelector(selectControl, (control) => control.shareToSocialMedia);

const selectNewFoundCertificates = createDraftSafeSelector(selectUserFlow, (userFlow) => userFlow.newCertificatesFound);

const selectNewCertificatesLoadingState = createDraftSafeSelector(
    selectUserFlow,
    (userFlow) => userFlow.newCertificateLoadingState
);

const selectDownloadProgress = createDraftSafeSelector(selectDownload, (download) => download.downloadProgressBar);

const controlSelectorService = {
    selectControl,
    selectSlugs,
    selectToken,
    selectUserEmail,
    selectUserCredentialNames,
    selectDownloadFormat,
    selectTokenValidation,
    selectSocialMediaProfiles,
    selectUserFlow,
    selectDownload,
    selectVerificationMethod,
    selectFlowLoading,
    selectBadgeIsParsing,
    selectSocialMediaShareState,
    selectNewFoundCertificates,
    selectNewCertificatesLoadingState,
    selectDownloadProgress,
};

export default controlSelectorService;
