import { saveAs } from "file-saver";
import JSZip from "jszip";
import type { PageType } from "polotno/model/page-model";
import type { StoreType } from "polotno/model/store";

import { sanitizeFileName } from "utils/sanitizeFileName";

/**
 * Downloads images from URLs, packs them into a zip file, and triggers the download.
 *
 * @param imageUrls - An array of image URLs to download and pack.
 * @param options - The export options.
 * @param options.zipFileName - The name of the zip file. Defaults to "downloaded_images.zip".
 * @returns A Promise that resolves when the download is complete.
 */
export const downloadImagesAsZip = async (
    imageUrls: string[],
    options: {
        zipFileName?: string;
    } = {}
) => {
    const { zipFileName } = options;
    const zip = new JSZip();

    try {
        // Create an array of promises for each image download.
        const imageDownloads = imageUrls.map(async (url, index) => {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch image from ${url}`);
            }
            const imageBlob = await response.blob();
            // Generate a name for each file.
            const fileName = sanitizeFileName(`image-${index + 1}`, { fileType: "png" });
            // Add the image blob to the zip with the generated filename.
            zip.file(fileName, imageBlob);
        });
        // Wait for all image downloads to complete.
        await Promise.all(imageDownloads);
        // Generate the zip file.
        const zipBlob = await zip.generateAsync({ type: "blob" });
        // Trigger the download.
        saveAs(zipBlob, zipFileName ?? "downloaded_images.zip");
    } catch (error) {
        console.error("Error downloading and zipping images:", error);
    }
};

/**
 * Exports the pages of a store to an array of base64 images.
 *
 * @param store - The store containing the pages to export.
 * @param options - The export options.
 * @param options.pixelRatio - The pixel ratio for exporting the pages. Defaults to 8.
 * @param options.mimeType - The MIME type of the exported images. Defaults to "image/png".
 * @returns A Promise that resolves to an array of base64 strings.
 */
export const exportPagesToArray = async (
    store: StoreType,
    options: {
        pixelRatio?: number;
        mimeType?: "image/png" | "image/jpeg";
        skipFirstPage?: boolean;
    } = {}
): Promise<string[]> => {
    const { pixelRatio, mimeType, skipFirstPage } = options;
    try {
        // Create an array of promises for each page export.
        const pageExports = store.pages
            .filter((_, index) => !(skipFirstPage && index === 0))
            .map(async (page: PageType) => {
                // Export the page as a base64 string.
                const base64 = await store.toDataURL({
                    pageId: page.id,
                    pixelRatio: pixelRatio ?? 8,
                    mimeType: mimeType ?? "image/png",
                });

                // Return the full base64 string including the data URL prefix.
                return base64;
            });
        // Wait for all page exports to complete and return the array.
        return await Promise.all(pageExports);
    } catch (error) {
        console.error("Error exporting pages to array:", error);
        throw error;
    }
};
