import type { FC, MouseEvent } from "react";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";

import useTranslation from "i18n/hooks/useTranslation";
import * as commonStyles from "utils/commonStyles";
import { getTranslatedCertificateOrBadge } from "utils/misc";
import { hideEmail } from "utils/strings.helpers";

import { Box, CircularProgress, Stack } from "@mui/material";

import AddEmailButton from "components/AddEmailButton";
import AddFacebookButton from "components/AddFacebookButton";
import AddLinkedInButton from "components/AddLinkedInButton";
import ContinueButton from "components/ContinueButton/ContinueButton";
import CheckBox from "components/shared/CheckBox/Checkbox";
import Divider from "components/shared/Divider";
import LinkTranslated from "components/shared/LinkTranslated";
import Typography from "components/shared/Typography";

import useLoadingThunk from "../../../../redux/hooks/loading";
import { useHitAccess } from "../../../../redux/hooks/validation";
import controlSelectorService from "../../../../redux/selector/control";
import eventSelectorService from "../../../../redux/selector/events";
import { useAppSelector } from "../../../../redux/store";
import { EAuthButton, EVariantTranslation } from "../../../../types/enums";
import { AnyObject } from "../../../../types/global";
import EmailVerification from "../EmailVerification";
import TokenValidationInput from "../TokenValidationInput";

import { EAuthFormTokenHandling } from "./authForm.enums";
import { otpTokenHandling } from "./authForm.helpers";
import { useValidationMethod, useValidatorSubmitForm } from "./authForm.hooks";
import * as styles from "./AuthForm.styles";

const AuthForm: FC = () => {
    const t = useTranslation();

    const [loaded, setLoaded] = useState(false);

    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);
    const recipientEmail = useAppSelector(controlSelectorService.selectUserEmail);
    const recipientCredentialNames = useAppSelector(controlSelectorService.selectUserCredentialNames);
    const selectValidateToken = useAppSelector(controlSelectorService.selectTokenValidation);
    const token = useAppSelector(controlSelectorService.selectToken);

    const { isPending, getFlowLoading } = useLoadingThunk();

    const { haveAccessToken, redirectToVerification } = useHitAccess();

    const isValidated = selectValidateToken.severity === "success";

    const { submitForm } = useValidatorSubmitForm();

    const {
        verifyMethod,
        changeVerifyMethod,
        onEmailClick,
        onSocialMediaClick,
        onVerifyCode,
        certificates,
        multipleCertificates,
        showSocialMediaProfilePictureUpload,
        continueWithEMailVerified,
        isTokenWasSent,
    } = useValidationMethod();

    const [isChecked, setIsChecked] = useState(false);

    const isSocialMediaEnabled = eventDetails?.event_social_media_mode_enabled;
    const isDynamicCredentialName = eventDetails?.event_has_dynamic_name ?? false;

    const getCredentialName = () => {
        if (isDynamicCredentialName) {
            return recipientCredentialNames.length
                ? recipientCredentialNames.join(", ")
                : `<${t("certificate_download.certificate_details.certificate_name_dynamic_placeholder")}>`;
        }
        return eventDetails?.event_name || "";
    };

    const onClickVerification = (
        type: "linkedin" | "facebook" | "email",
        event: string | AnyObject<any>,
        extraFlags?: string[] | undefined
    ) => {
        if (haveAccessToken[type]) redirectToVerification(token, type);
        else {
            if (type === "email") {
                changeVerifyMethod(EAuthButton.EMAIL);
                // Set timestamp and last used token to local storage when user clicked on email verification
                otpTokenHandling().set(EAuthFormTokenHandling.BOTH, token);
            } else changeVerifyMethod(EAuthButton.SOCIAL_MEDIA);
            submitForm(event, type, extraFlags);
        }
    };

    useEffect(() => {
        // Check if current token is same as last used token
        const lastUsedTokenIsCurrent = otpTokenHandling().checkToken(token);
        if (token && !loaded) {
            // Get local storage item
            const otpTokenSent = otpTokenHandling().get(EAuthFormTokenHandling.OTP_TOKEN_SENT);
            if (otpTokenSent) {
                // Check if the sent timestamp was saved in the last 2 minutes
                const hasRequestedOtpRecently = otpTokenHandling().checkLastClickedLongerThan(2); // 2 minutes expiration
                // Save verification method if token was already sent in the last 2 minutes and if user used the same token as before
                if (!hasRequestedOtpRecently && lastUsedTokenIsCurrent) changeVerifyMethod(EAuthButton.EMAIL, true);
                // Remove the e-mail-token-sent timestamp and last_used_token from local storage
                otpTokenHandling().remove(EAuthFormTokenHandling.BOTH);
            }
            setLoaded(true);
        }
        // Initial check for sent email timestamp
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const firstStepVerificationInformation = multipleCertificates
        ? t("certificate.authForm.description_steps.first_step_content_multy_certificates")
        : t("certificate.authForm.description_steps.first_step_content");

    const firstStepInformation = showSocialMediaProfilePictureUpload
        ? t("certificate.authForm.description_steps.first_step_content_upload_picture_with_social_media")
        : firstStepVerificationInformation;

    return (
        <Stack spacing={4}>
            <Typography variant="h3" gutterBottom={false} mt={{ md: 0, xs: 5 }}>
                {t("certificate.authForm.heading", {
                    count: certificates.length,
                    certificateType: getTranslatedCertificateOrBadge(
                        eventDetails?.event_type,
                        multipleCertificates
                            ? EVariantTranslation.LOWERCASE_PLURAL
                            : EVariantTranslation.LOWERCASE_SINGULAR
                    ),
                } as any)}
            </Typography>
            <Divider />
            <div>
                <Box sx={{ ...commonStyles.flex, ...styles.infoRow }}>
                    <Typography variant="body1" color="text.secondary" sx={styles.infosText}>
                        {t("certificate_download.certificate_details.certificate_name", {
                            certificateType: getTranslatedCertificateOrBadge(
                                eventDetails?.event_type,
                                multipleCertificates
                                    ? EVariantTranslation.UPPERCAE_PLURAL
                                    : EVariantTranslation.UPPERCASE_SINGULAR
                            ),
                        } as any)}
                    </Typography>
                    <Typography variant="body1">{getCredentialName()}</Typography>
                </Box>
                <Box sx={{ ...commonStyles.flex, ...styles.infoRow }}>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={styles.infosText}
                        gutterBottom={!!recipientEmail}
                    >
                        {t("certificate.authForm.issued_by")}
                    </Typography>
                    <Typography variant="body1" gutterBottom={recipientEmail}>
                        {eventDetails?.event_certificate_issuer || eventDetails?.organisation_name || ""}
                    </Typography>
                </Box>
                {recipientEmail && (
                    <Box sx={{ ...commonStyles.flex, ...styles.infoRow }}>
                        <Typography variant="body1" color="text.secondary" sx={styles.infosText} gutterBottom={false}>
                            {t("certificate.authForm.issued_for")}
                        </Typography>
                        <Typography variant="body1" gutterBottom={false}>
                            {hideEmail(recipientEmail) || "-"}
                        </Typography>
                    </Box>
                )}
            </div>
            <Divider />
            {!isValidated && !isPending("token") && <TokenValidationInput />}
            {(isPending("token") || getFlowLoading("validateOtpToken")) && (
                <Stack justifyContent="center" alignItems="center" height={150}>
                    <CircularProgress />
                </Stack>
            )}
            {!getFlowLoading("validateOtpToken") && token !== "" && isValidated && (
                <>
                    <Typography variant="h5">
                        {isSocialMediaEnabled
                            ? t("certificate.authForm.description_steps.heading", {
                                  count: certificates.length,
                                  certificateType: getTranslatedCertificateOrBadge(
                                      eventDetails?.event_type,
                                      multipleCertificates
                                          ? EVariantTranslation.LOWERCASE_PLURAL
                                          : EVariantTranslation.LOWERCASE_SINGULAR
                                  ),
                              } as any)
                            : t("certificate.authForm.form.email_verification_heading")}
                    </Typography>
                    {isSocialMediaEnabled && (
                        <div>
                            <Box sx={{ ...commonStyles.flex, ...styles.infoRow }}>
                                <Typography variant="h6" sx={styles.stepsText}>
                                    {t("certificate.authForm.description_steps.first_step")}
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    {firstStepInformation}
                                </Typography>
                            </Box>
                            <Box sx={{ ...commonStyles.flex, ...styles.infoRow }}>
                                <Typography variant="h6" sx={styles.stepsText} gutterBottom={false}>
                                    {t("certificate.authForm.description_steps.second_step")}
                                </Typography>
                                <Typography variant="body1" color="text.secondary" gutterBottom={false}>
                                    {t("certificate.authForm.description_steps.second_step_content", {
                                        count: certificates.length,
                                        certificateType: getTranslatedCertificateOrBadge(
                                            eventDetails?.event_type,
                                            multipleCertificates
                                                ? EVariantTranslation.LOWERCASE_PLURAL
                                                : EVariantTranslation.LOWERCASE_SINGULAR
                                        ),
                                    } as any)}
                                </Typography>
                            </Box>
                        </div>
                    )}
                    <Box sx={styles.termsOfUseText}>
                        <CheckBox
                            checked={isChecked}
                            onChange={() => setIsChecked((prev) => !prev)}
                            label={
                                <Typography mb={0} sx={{ lineHeight: "160%" }}>
                                    <Trans i18nKey="certificate.authForm.form.consent_terms">
                                        I agree to the <LinkTranslated moveTo="/terms">terms</LinkTranslated> by using
                                        this software.
                                    </Trans>
                                </Typography>
                            }
                        />
                    </Box>
                    {showSocialMediaProfilePictureUpload ? (
                        <Box gap={2} display="flex" flexDirection={{ md: "row", xs: "column" }}>
                            {eventDetails.event_auth_methods?.includes("linkedin") && (
                                <AddLinkedInButton
                                    id="linkedin"
                                    disabled={!isChecked}
                                    onClick={(event: MouseEvent<HTMLButtonElement>) =>
                                        onClickVerification("linkedin", event)
                                    }
                                    label={t("certificate.authForm.form.button_linkedin.text_upload_profile_picture")}
                                />
                            )}
                            {!eventDetails.settings_exclude_facebook_auth &&
                                eventDetails.event_auth_methods?.includes("facebook") && (
                                    <AddFacebookButton
                                        id="facebook"
                                        disabled={!isChecked}
                                        onClick={(event: MouseEvent<HTMLButtonElement>) =>
                                            onClickVerification("facebook", event)
                                        }
                                        label={t(
                                            "certificate.authForm.form.button_facebook.text_upload_profile_picture"
                                        )}
                                    />
                                )}
                            <ContinueButton id="continue" disabled={!isChecked} onClick={continueWithEMailVerified} />
                        </Box>
                    ) : (
                        <>
                            {!isSocialMediaEnabled || verifyMethod === EAuthButton.EMAIL || multipleCertificates ? (
                                <EmailVerification
                                    onAuthWithSocialMedia={onSocialMediaClick}
                                    onEmailSent={onEmailClick}
                                    onVerifyCode={onVerifyCode}
                                    isSocialMediaEnabled={!!isSocialMediaEnabled}
                                    isSingleCertificate={!multipleCertificates}
                                    disabled={!isChecked}
                                    disableResend={isTokenWasSent}
                                />
                            ) : (
                                <Box gap={2} display="flex" flexDirection={{ md: "row", xs: "column" }}>
                                    {eventDetails.event_auth_methods?.includes("linkedin") && (
                                        <AddLinkedInButton
                                            id="linkedin"
                                            disabled={!isChecked}
                                            onClick={(event: MouseEvent<HTMLButtonElement>) =>
                                                onClickVerification("linkedin", event)
                                            }
                                        />
                                    )}
                                    {!eventDetails.settings_exclude_facebook_auth &&
                                        eventDetails.event_auth_methods?.includes("facebook") && (
                                            <AddFacebookButton
                                                id="facebook"
                                                disabled={!isChecked}
                                                onClick={(event: MouseEvent<HTMLButtonElement>) =>
                                                    onClickVerification("facebook", event)
                                                }
                                            />
                                        )}
                                    {eventDetails.event_auth_methods?.includes("email") && (
                                        <AddEmailButton
                                            id="email"
                                            disabled={!isChecked}
                                            onClick={(event: MouseEvent<HTMLButtonElement>) =>
                                                onClickVerification("email", event)
                                            }
                                        />
                                    )}
                                </Box>
                            )}
                        </>
                    )}
                </>
            )}
        </Stack>
    );
};

export default AuthForm;
