import { useEffect } from "react";
import { Link } from "react-router-dom";

import { EVariantTranslation } from "types/enums";

import { Box, Skeleton } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";

import LanguageSelector from "components/LanguageSelector";

import useTranslation from "../../i18n/hooks/useTranslation";
import { useGetEvent } from "../../redux/hooks/events";
import { useGetCertificateDetails, useGetCertificateProps } from "../../redux/hooks/queryHooks";
import eventSelectorService from "../../redux/selector/events";
import { useAppSelector } from "../../redux/store";
import { getTranslatedCertificateOrBadge } from "../../utils/misc";

import * as styles from "./NavBar.styles";

function NavBar() {
    const t = useTranslation();
    const event = useAppSelector(eventSelectorService.selectEvent);
    const eventIsFetched = useAppSelector(eventSelectorService.eventIsFetched);
    const eventIsNotInitialFetched = useAppSelector(eventSelectorService.eventNotInitialFetched);

    const { data: certificateDetails } = useGetCertificateDetails();
    const { refetch, isSuccess, isUninitialized } = useGetCertificateProps();

    const initialFetchEvent = useGetEvent();

    useEffect(() => {
        if (eventIsNotInitialFetched) {
            if (!isSuccess && !isUninitialized) refetch();
            initialFetchEvent(() => null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (event.event.event_type) {
            document.title = t("credential_title", {
                TYPE: getTranslatedCertificateOrBadge(event.event.event_type, EVariantTranslation.LOWERCASE_SINGULAR),
            });
        }
    }, [event.event.event_type, t]);

    return (
        <Box sx={styles.appBar}>
            <Toolbar>
                <Box sx={styles.ToolbarWrapper}>
                    {!isUninitialized ? (
                        <>
                            {!eventIsFetched || !isSuccess || !event?.event.event_logo_url ? (
                                <Skeleton variant="rectangular" width={200} height={50} />
                            ) : (
                                <Link
                                    to={certificateDetails?.event__certificate_course_url || window.location.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Box
                                        component="img"
                                        src={event?.event.event_logo_url}
                                        alt="logo"
                                        sx={styles.logoStyles}
                                    />
                                </Link>
                            )}
                        </>
                    ) : (
                        <div
                        // Empty div to place language selector correctly.
                        // Otherwise the LanguageSelector will be rendered left instead of right.
                        />
                    )}
                    <LanguageSelector />
                </Box>
            </Toolbar>
        </Box>
    );
}

export default NavBar;
