/* eslint-disable no-nested-ternary */
import { ICertificatePropsResult } from "features/certificateDownload/api/queries.app";
import { IConfigPdfCreation, IParticipantPdfCreation } from "features/certificateDownload/types/utils";
import BadgeEngine from "features/certificateDownload/utils/BadgeEngine/BadgeEngine";
import { cloneDeep } from "lodash";
import QRCode from "qrcode-svg";
import SVGtoPDF from "svg-to-pdfkit";

import { formatDate } from "utils/dates.helpers";
import { urlToBase64 } from "utils/urlToBase64";

import { AnyObject } from "../../../../types/global";
import { generateCertificateFileName } from "../../../../utils/sanitizeFileName";
import { deserializeBadgeProps } from "../BadgeEngine/BadgeEngine.helpers";

// Based on ISO standards (https://pdfkit.org/docs/paper_sizes.html)
const A4_WIDTH = 595.28;
const A4_HEIGHT = 841.89;
const LETTER_WIDTH = 612;
const LETTER_HEIGHT = 792;

const DATE_FORMAT = "DD Month YYYY";

/**
 * Export certificate/badge image and align it for the pdf.
 * @param badgeProps{VbDesigner.IBadgeProps}
 * @param badgeFormat{VbDesigner.BadgeFormat}
 * @param scale {number}
 * @returns
 */
export const importImagePdf = async (
    badgeProps: VbDesigner.IBadgeProps,
    badgeFormat: VbDesigner.BadgeFormat,
    scale = 2
) => {
    const badgeEngine = new BadgeEngine(badgeFormat, "RECIPIENT_VIEW");

    if (!badgeEngine) {
        throw Error("Error initialising badge engine");
    }
    const badgeExport = await badgeEngine.exportBadge(badgeProps, { scale });
    const img = new Image();
    (img as AnyObject).src = badgeExport;

    let isLandscape = false;
    let isLetter = false;
    switch (badgeFormat) {
        case "US_LETTER_LANDSCAPE":
            isLandscape = true;
            isLetter = true;
            break;
        case "US_LETTER_PORTRAIT":
            isLetter = true;
            break;
        case "A4_LANDSCAPE":
            isLandscape = true;
            break;
        default:
            break;
    }

    return new Promise((resolve) => {
        img.onload = () => {
            // Constants
            const CERT_MARGIN = 0;
            const BLUR_AMOUNT = 0;
            const CERT_RATIO = badgeEngine.CANVAS_HEIGHT / badgeEngine.CANVAS_WIDTH;

            // Scale dimensions
            const certMarginScaled = CERT_MARGIN * scale;
            const baseWidth = isLetter ? LETTER_WIDTH : A4_WIDTH;
            const baseHeight = isLetter ? LETTER_HEIGHT : A4_HEIGHT;
            /**
             * The documentWidth/-Height represents the area where the certificate image will be rendered in.
             * On the single page pdf option, this area is the left half of a landscape page.
             * If the layout of the page is landscape we have to swap baseWidth and baseHeight.
             * Here is an illustration: https://futurenextde.sharepoint.com/:o:/s/Development/EUC-iqlwkZRInpgLFtzfWywB8adTfAmC3Ga7OkK5NSb6ew?e=Ai1dw5
             */
            const documentWidth = (isLandscape ? baseHeight : baseWidth) * scale;
            const documentHeight = (isLandscape ? baseWidth : baseHeight) * scale;

            // Clac. cert dimensions
            const certWidth = documentWidth - certMarginScaled;
            const certHeight = (documentWidth - certMarginScaled) * CERT_RATIO;

            // Get offsets to center cert image
            const offsetLeft = (documentWidth - certWidth) / 2;
            const offsetTop = (documentHeight - certHeight) / 2;

            // Get a canvas with A4 dimensions
            const canvas = document.createElement("canvas");
            if (!canvas) throw Error("No canvas provided.");
            const ctx = canvas.getContext("2d");
            canvas.width = documentWidth;
            canvas.height = documentHeight;

            // Add blur
            if (ctx) {
                ctx.shadowBlur = BLUR_AMOUNT;
                ctx.shadowColor = "rgba(0,0,0,0.35)";

                // Draw image
                ctx.fillStyle = "transparent";
                ctx?.fillRect(0, 0, documentWidth, documentHeight);
                ctx?.drawImage(img, offsetLeft, offsetTop, certWidth, certHeight);
            }
            // Resolve with blob image
            resolve(canvas.toDataURL());
        };
    });
};

/**
 * Get the Certificate pdf according to which language is set. Defaults to English
 * @param language {"en" | "de"}
 * @returns {string}
 */
export const getPdfTexts = (language: "en" | "de") => {
    let extractedLanguage: "en" | "de" = "en";
    switch (language) {
        case "de":
            extractedLanguage = "de";
            break;
        default:
            extractedLanguage = "en";
            break;
    }
    const texts: Record<"en" | "de", Record<string, string>> = {
        en: {
            Certificate: "Certificate",
            CertificateId: "Identification Number",
            ExpirationDate: "Expiration Date",
            Header: "About",
            IssueDate: "Issue Date",
            IssuedBy: "Issued by:",
            NameOfRecipient: "Name of Recipient",
            ValidateNow: "Validate now",
            ValidationUrl: "Validation URL",
            VerifiedBy: "Verified by",
            FileNameTitle: "Certificate",
            NoExpiration: "No expiration",
            file_name: "Certificate",
        },
        de: {
            Certificate: "Zertifikat",
            CertificateId: "Identifikationsnummer",
            ExpirationDate: "Ablaufdatum",
            Header: "Weitere Informationen",
            IssueDate: "Ausstellungsdatum",
            IssuedBy: "Dieses Zertifikat wurde ausgestellt von",
            NameOfRecipient: "Zertifikatsempfänger/in",
            ValidateNow: "Jetzt validieren",
            ValidationUrl: "Validierungs-URL",
            VerifiedBy: "Verifiziert durch",
            FileNameTitle: "Zertifikat",
            NoExpiration: "Kein Ablaufdatum ",
            file_name: "Zertifikat",
        },
    };
    return texts[extractedLanguage];
};

/**
 * Get the profile picture from the linkedin/facebook profile and add it to the profile picture placeholder.
 * @param payload
 * @param badgeEngine
 * @returns badgeProps
 */
export const setProfilePictureInBadgeProps = async (
    payload: ICertificatePropsResult
): Promise<VbDesigner.IBadgeProps> => {
    let badgePropsObject: VbDesigner.IBadgeProps | null = cloneDeep(payload.badgeprops);

    if (payload?.linkedin) {
        // Get linkedin profile information and add them to the badge props.
        const profile = payload.linkedin;

        let linkedinPic;
        if (profile.profilePicture) {
            const linkedinPicElements = profile.profilePicture["displayImage~"].elements;
            // Get image with highest resolution
            linkedinPic = linkedinPicElements[linkedinPicElements.length - 1].identifiers[0].identifier;

            // Update linkedin picture -> image gets loaded when deserializing images
            if (badgePropsObject) badgePropsObject.userInformationObjects[0].imgBlobUrl = linkedinPic;
        } else {
            // use default image from preview
            linkedinPic = badgePropsObject?.userInformationObjects[0]?.img?.src;
        }
    } else if (payload.facebook) {
        // Get profile information and add them to the badge props
        const profile = payload.facebook;

        const facebookPic = profile.picture;

        // Load image
        if (badgePropsObject) badgePropsObject.userInformationObjects[0].imgBlobUrl = facebookPic;
    }

    if (badgePropsObject) badgePropsObject = await deserializeBadgeProps(badgePropsObject, payload.format);
    if (!badgePropsObject) throw new Error("badgePropsObject is null");
    return badgePropsObject;
};

/**
 * Multi page pdf creation
 * @param {*} config
 * @param {*} participant
 */
export const handleMultiPagePdfCreation = async (config: IConfigPdfCreation, participant: IParticipantPdfCreation) => {
    try {
        const localizedPdfTexts = getPdfTexts(config.pdfLanguage);

        let pageFormat = "LETTER";
        let pageLayout = "portrait";
        switch (config.badgeFormat) {
            case "A4_LANDSCAPE":
                pageFormat = "A4";
                pageLayout = "landscape";
                break;
            case "US_LETTER_LANDSCAPE":
                pageFormat = "LETTER";
                pageLayout = "landscape";
                break;
            case "US_LETTER_PORTRAIT":
                pageFormat = "LETTER";
                pageLayout = "portrait";
                break;
            case "BADGE_1_TO_1":
            case "LEGACY_BADGE_3_TO_4":
            case "A4_PORTRAIT":
            default:
                pageFormat = "A4";
                pageLayout = "portrait";
                break;
        }

        // Start PDF doc
        const font = await fetch(
            "https://vbstatic.blob.core.windows.net/static/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf"
        );
        const doc = new (window as Window).PDFDocument({
            size: pageFormat,
            layout: pageLayout as VbDesigner.TPageLayout,
        });
        const arrayBuffer = await font.arrayBuffer();
        doc.registerFont("OpenSans", arrayBuffer);
        doc.font("OpenSans");
        const stream = doc.pipe((window as Window).blobStream());
        const fontSize = 12;
        const backgroundColor = "#E5E5E5";
        const headerColor = "#323232";
        const fontColor = "#7e7e7e";
        const fontColorBold = "#3f3f3f";
        const fontColorLight = "#B8B2B2";
        const textStartXPosition = 65;
        let textStartYPosition = 65;

        // Page 1
        if (!config.certificateBlobImageUrl) throw new Error("No image provided");
        const img = await urlToBase64(config.certificateBlobImageUrl);
        doc.image(img, 0, 0, { width: doc.page.width });

        // Page 2
        doc.addPage();

        // Draw background
        doc.rect(0, 0, doc.page.width, 15).fill(backgroundColor);
        doc.rect(0, 0, 15, doc.page.height).fill(backgroundColor);
        doc.rect(doc.page.width - 15, 0, doc.page.width, doc.page.height).fill(backgroundColor);
        doc.rect(0, doc.page.height - 15, doc.page.width, doc.page.height).fill(backgroundColor);

        // Page 2 content
        doc.fillColor(headerColor)
            .fontSize(22)
            .text(localizedPdfTexts.Header, textStartXPosition, textStartYPosition, {
                width: Number(doc.page.width - 50),
                align: "left",
            });

        // QR code
        const qrcode = new QRCode({
            content: decodeURIComponent(config.customCertificateValidationUrl),
            padding: 0,
            width: 102,
            height: 102,
            color: "#000000",
            background: "#ffffff",
            ecl: "M",
        }).svg();
        SVGtoPDF(doc, qrcode, doc.page.width - 150, textStartYPosition - 15);

        // "Validate now" text
        const validateNowTextPosX = config.pdfLanguage === "de" ? doc.page.width - 145 : doc.page.width - 140;
        doc.fillColor(fontColorLight)
            .fontSize(10)
            .text(localizedPdfTexts.ValidateNow, validateNowTextPosX, textStartYPosition + 70);

        // Line
        textStartYPosition += 27;
        doc.fillColor(fontColorLight)
            .path(
                `M ${textStartXPosition}, ${textStartYPosition} L ${
                    textStartXPosition + (config.pdfLanguage == "de" ? 233 : 63)
                }, ${textStartYPosition}`
            )
            .stroke();

        // Certificate Information
        doc.fillColor(fontColor)
            .fontSize(fontSize)
            .text(localizedPdfTexts.IssuedBy, textStartXPosition, (textStartYPosition += 27));
        doc.fillColor(fontColorBold)
            .fontSize(fontSize)
            .text(config.eventCertificateIssuer, textStartXPosition, (textStartYPosition += 20));

        doc.fillColor(fontColor)
            .fontSize(fontSize)
            .text(localizedPdfTexts.NameOfRecipient, textStartXPosition, (textStartYPosition += 30));
        doc.fillColor(fontColorBold)
            .fontSize(fontSize)
            .text(participant.recipient_name, textStartXPosition, (textStartYPosition += 20));

        doc.fillColor(fontColor)
            .fontSize(fontSize)
            .text(localizedPdfTexts.Certificate, textStartXPosition, (textStartYPosition += 30));
        doc.fillColor(fontColorBold)
            .fontSize(fontSize)
            .text(config.eventName, textStartXPosition, (textStartYPosition += 20));

        doc.fillColor(fontColor)
            .fontSize(fontSize)
            .text(localizedPdfTexts.IssueDate, textStartXPosition, (textStartYPosition += 30));
        const issueDatePosX = config.pdfLanguage === "de" ? textStartXPosition + 110 : textStartXPosition + 70;
        doc.fillColor(fontColorBold)
            .fontSize(fontSize)
            .text(
                formatDate(participant.issue_date, DATE_FORMAT, config.pdfLanguage),
                issueDatePosX,
                textStartYPosition
            );

        const expirationDatePosX = config.pdfLanguage === "de" ? textStartXPosition + 300 : textStartXPosition + 315;
        if (participant.expiration_date) {
            doc.fillColor(fontColor)
                .fontSize(fontSize)
                .text(localizedPdfTexts.ExpirationDate, textStartXPosition + 220, textStartYPosition);
            doc.fillColor(fontColorBold)
                .fontSize(fontSize)
                .text(
                    formatDate(participant.expiration_date, DATE_FORMAT, config.pdfLanguage),
                    expirationDatePosX,
                    textStartYPosition
                );
        }

        if (participant.validationPageEnabled) {
            doc.fillColor(fontColor)
                .fontSize(fontSize)
                .text(localizedPdfTexts.CertificateId, textStartXPosition, (textStartYPosition += 30));
            doc.fillColor(fontColorBold)
                .fontSize(fontSize)
                .text(participant.certificate_id, textStartXPosition, (textStartYPosition += 20));

            doc.fill(fontColor)
                .fontSize(fontSize)
                .text(localizedPdfTexts.ValidationUrl, textStartXPosition, (textStartYPosition += 30));
            doc.fillColor(fontColorBold).text(
                decodeURIComponent(config.customCertificateValidationUrl),
                textStartXPosition,
                (textStartYPosition += 20),
                {
                    width: Number(doc.page.width) - 3 * textStartXPosition,
                    align: "justify",
                }
            );
            doc.link(
                textStartXPosition,
                textStartYPosition,
                Number(doc.page.width) - 3 * textStartXPosition,
                30,
                decodeURIComponent(config.customCertificateValidationUrl)
            );
        }

        doc.end();
        stream.on("finish", () => {
            const url = stream.toBlobURL("application/pdf");
            const link = document.createElement("a");
            link.href = url;
            link.download = `${
                (participant.recipient_name ? `${participant.recipient_name} - ` : "") + localizedPdfTexts.file_name
            }.pdf`;

            link.dispatchEvent(new MouseEvent("click"));
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

/**
 * Multi page pdf creation
 * @param {*} config
 * @param {*} participant
 */
export const handleMultiPagePdfCreationWithReturn = async (
    config: IConfigPdfCreation,
    participant: IParticipantPdfCreation,
    font: Uint8Array
) => {
    try {
        const localizedPdfTexts = getPdfTexts(config.pdfLanguage);

        let pageFormat = "LETTER";
        let pageLayout = "portrait";
        switch (config.badgeFormat) {
            case "A4_LANDSCAPE":
                pageFormat = "A4";
                pageLayout = "landscape";
                break;
            case "US_LETTER_LANDSCAPE":
                pageFormat = "LETTER";
                pageLayout = "landscape";
                break;
            case "US_LETTER_PORTRAIT":
                pageFormat = "LETTER";
                pageLayout = "portrait";
                break;
            case "BADGE_1_TO_1":
            case "LEGACY_BADGE_3_TO_4":
            case "A4_PORTRAIT":
            default:
                pageFormat = "A4";
                pageLayout = "portrait";
                break;
        }
        const doc = new (window as Window).PDFDocument({
            size: pageFormat,
            layout: pageLayout as VbDesigner.TPageLayout,
        });
        const arrayBuffer = font;
        doc.registerFont("OpenSans", arrayBuffer);
        doc.font("OpenSans");
        const stream = doc.pipe((window as Window).blobStream());
        const fontSize = 12;
        const backgroundColor = "#E5E5E5";
        const headerColor = "#323232";
        const fontColor = "#7e7e7e";
        const fontColorBold = "#3f3f3f";
        const fontColorLight = "#B8B2B2";
        const textStartXPosition = 65;
        let textStartYPosition = 65;

        // Start PDF doc
        // Page 1
        if (!config.certificateBlobImageUrl) throw new Error("No image provided");
        const img = await urlToBase64(config.certificateBlobImageUrl);
        doc.image(img, 0, 0, { width: doc.page.width });

        // Page 2
        doc.addPage();

        // Draw background
        doc.rect(0, 0, doc.page.width, 15).fill(backgroundColor);
        doc.rect(0, 0, 15, doc.page.height).fill(backgroundColor);
        doc.rect(doc.page.width - 15, 0, doc.page.width, doc.page.height).fill(backgroundColor);
        doc.rect(0, doc.page.height - 15, doc.page.width, doc.page.height).fill(backgroundColor);

        // Page 2 content
        doc.fillColor(headerColor)
            .fontSize(22)
            .text(localizedPdfTexts.Header, textStartXPosition, textStartYPosition, {
                width: Number(doc.page.width - 50),
                align: "left",
            });

        // QR code
        const qrcode = new QRCode({
            content: decodeURIComponent(config.customCertificateValidationUrl),
            padding: 0,
            width: 102,
            height: 102,
            color: "#000000",
            background: "#ffffff",
            ecl: "M",
        }).svg();
        SVGtoPDF(doc, qrcode, doc.page.width - 150, textStartYPosition - 15);

        // "Validate now" text
        const validateNowTextPosX = config.pdfLanguage === "de" ? doc.page.width - 145 : doc.page.width - 140;
        doc.fillColor(fontColorLight)
            .fontSize(10)
            .text(localizedPdfTexts.ValidateNow, validateNowTextPosX, textStartYPosition + 70);

        // Line
        textStartYPosition += 27;
        doc.fillColor(fontColorLight)
            .path(
                `M ${textStartXPosition}, ${textStartYPosition} L ${
                    textStartXPosition + (config.pdfLanguage == "de" ? 233 : 63)
                }, ${textStartYPosition}`
            )
            .stroke();

        // Certificate Information
        doc.fillColor(fontColor)
            .fontSize(fontSize)
            .text(localizedPdfTexts.IssuedBy, textStartXPosition, (textStartYPosition += 27));
        doc.fillColor(fontColorBold)
            .fontSize(fontSize)
            .text(config.eventCertificateIssuer, textStartXPosition, (textStartYPosition += 20));

        doc.fillColor(fontColor)
            .fontSize(fontSize)
            .text(localizedPdfTexts.NameOfRecipient, textStartXPosition, (textStartYPosition += 30));
        doc.fillColor(fontColorBold)
            .fontSize(fontSize)
            .text(participant.recipient_name, textStartXPosition, (textStartYPosition += 20));

        doc.fillColor(fontColor)
            .fontSize(fontSize)
            .text(localizedPdfTexts.Certificate, textStartXPosition, (textStartYPosition += 30));
        doc.fillColor(fontColorBold)
            .fontSize(fontSize)
            .text(config.eventName, textStartXPosition, (textStartYPosition += 20));

        doc.fillColor(fontColor)
            .fontSize(fontSize)
            .text(localizedPdfTexts.IssueDate, textStartXPosition, (textStartYPosition += 30));
        const issueDatePosX = config.pdfLanguage === "de" ? textStartXPosition + 110 : textStartXPosition + 70;
        doc.fillColor(fontColorBold)
            .fontSize(fontSize)
            .text(
                formatDate(participant.issue_date, DATE_FORMAT, config.pdfLanguage),
                issueDatePosX,
                textStartYPosition
            );

        const expirationDatePosX = config.pdfLanguage === "de" ? textStartXPosition + 300 : textStartXPosition + 315;
        if (participant.expiration_date) {
            doc.fillColor(fontColor)
                .fontSize(fontSize)
                .text(localizedPdfTexts.ExpirationDate, textStartXPosition + 220, textStartYPosition);
            doc.fillColor(fontColorBold)
                .fontSize(fontSize)
                .text(
                    formatDate(participant.expiration_date, DATE_FORMAT, config.pdfLanguage),
                    expirationDatePosX,
                    textStartYPosition
                );
        }

        doc.fillColor(fontColor)
            .fontSize(fontSize)
            .text(localizedPdfTexts.CertificateId, textStartXPosition, (textStartYPosition += 30));
        doc.fillColor(fontColorBold)
            .fontSize(fontSize)
            .text(participant.certificate_id, textStartXPosition, (textStartYPosition += 20));

        doc.fill(fontColor)
            .fontSize(fontSize)
            .text(localizedPdfTexts.ValidationUrl, textStartXPosition, (textStartYPosition += 30));
        doc.fillColor(fontColorBold).text(
            decodeURIComponent(config.customCertificateValidationUrl),
            textStartXPosition,
            (textStartYPosition += 20),
            {
                width: Number(doc.page.width) - 3 * textStartXPosition,
                align: "justify",
            }
        );
        doc.link(
            textStartXPosition,
            textStartYPosition,
            Number(doc.page.width) - 3 * textStartXPosition,
            30,
            decodeURIComponent(config.customCertificateValidationUrl)
        );
        doc.end();
        const finishStream = new Promise((resolve) => {
            stream.on("finish", () => resolve(stream.toBlobURL("application/pdf")));
        });

        try {
            const url = (await finishStream) as string;
            const response = await fetch(url);
            const blob = await response.blob();
            const fileName = generateCertificateFileName(participant, "pdf");

            return {
                pdfBlob: blob,
                fileName,
            };
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("An error occurred:", error);
            return {
                pdfBlob: "error",
                fileName: "",
            };
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return {
            pdfBlob: "error",
            fileName: "",
        };
    }
};
