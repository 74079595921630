/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo, useState } from "react";
import eventSelectorService from "redux/selector/events";
import { useAppSelector } from "redux/store";

import { findRecipientInformation } from "utils/findRecipientInformation";

import Grid from "@mui/material/Grid";

import useGetUserAgent from "../../../../utils/getUserAgent";
import { buildUrl } from "../../../../utils/misc";

import AppleWalletModal from "./AppleWalletModal/AppleWalletModal";
import WalletButton from "./WalletButton";

type Props = {
    certificate: Data;
    certificateDetails: ICertificateDetails;
};

const WalletSection = ({ certificate, certificateDetails }: Props) => {
    const event = useAppSelector(eventSelectorService.selectEvent);
    const [appleWalletModalOpen, setAppleWalletModalOpen] = useState(false);

    const walletParams = useMemo(
        () => ({
            orga_name: certificateDetails.event__certificate_issuer,
            recipient_name: findRecipientInformation(certificate.field_mapping, "username") || "",
            certificate_name: certificate.credential_name || certificateDetails.event__name,
            identification_number: certificate.certificate_id,
            issue_date: certificate.issue_date,
            expiration_date: certificate.expiration_date,
            validation_url: certificate.validation_url,
            logo_image: event.event.event_wallet_pass_logo_url,
            hero_image: event.event.event_wallet_hero_image_url,
            background_color: event.event.event_wallet_background_color,
        }),
        [
            certificate.certificate_id,
            certificate.expiration_date,
            certificate.field_mapping,
            certificate.issue_date,
            certificate.validation_url,
            certificate.credential_name,
            certificateDetails.event__certificate_issuer,
            certificateDetails.event__name,
            event.event.event_wallet_background_color,
            event.event.event_wallet_hero_image_url,
            event.event.event_wallet_pass_logo_url,
        ]
    );

    const { walletAvaibleCheck } = useGetUserAgent();

    /**
     * Handles the click on the "Add to Apple Wallet" button.
     * The endpoint to for the apple wallet will download a .pkpass file.
     * The endpoint for the google wallet will open a tab that will add the certificate to the google wallet.
     * @param type "apple" | "google"
     */
    const handleAddToWallet = (type: "apple" | "google") => {
        if (type === "apple" && !walletAvaibleCheck.apple) {
            setAppleWalletModalOpen(true);
            return;
        }

        const url =
            type === "apple"
                ? `${process.env.VB_FUNCTIONS_PY_API_URL}/wallet/apple`
                : `${process.env.VB_FUNCTIONS_PY_API_URL}/wallet/google`;

        window.open(buildUrl(url, walletParams), "_blank");
    };

    return (
        <>
            <Grid
                container
                direction="row"
                // Define style as important as other style classes are overriding it
                sx={{ marginTop: "8px !important" }}
                gap={2}
            >
                <Grid item>
                    <WalletButton type="apple" onClick={() => handleAddToWallet("apple")} />
                </Grid>
                <Grid item>
                    <WalletButton type="google" onClick={() => handleAddToWallet("google")} />
                </Grid>
            </Grid>
            <AppleWalletModal
                walletUrl={buildUrl(`${process.env.VB_FUNCTIONS_PY_API_URL}/wallet/apple`, walletParams)}
                open={appleWalletModalOpen}
                setOpen={setAppleWalletModalOpen}
            />
        </>
    );
};

export default WalletSection;
