import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";

import { CertificateDownloaderApiRoutes } from "../../features/certificateDownload/api/routes";
import { urlToBase64 } from "../../utils/urlToBase64";
import { certificatePropsApi } from "../slices/query/certificateProps";
import type { TCertificateEvent, TCertificateEventDetails } from "../slices/types/event.types";
import type { ThunkAPIConfig } from "../store";

import { fetcher } from "./fetch";
import redirectActionService from "./redirections";

/** Fetch the event details and store issue and expiration date */
const getEventDetails: AsyncThunk<TCertificateEventDetails, undefined, ThunkAPIConfig> = createAsyncThunk<
    TCertificateEventDetails,
    undefined,
    ThunkAPIConfig
>("events/getEventDetails", async (_args, thunkAPI): Promise<TCertificateEventDetails> => {
    const { control } = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch;
    const res = await fetcher<TCertificateEventDetails>(
        CertificateDownloaderApiRoutes.getDetails(control.token),
        undefined,
        (err) =>
            dispatch(
                redirectActionService.doRedirect({
                    reason: err.response?.status === 429 ? "too_many_requests" : "no_data",
                    noNotification: err.response?.status !== 429,
                })
            )
    );

    if (!res) {
        return {};
    }

    return { issue_date: res?.issue_date, expiration_date: res?.expiration_date };
});

/** Fetch the basic event information */
const getEvent: AsyncThunk<TCertificateEvent, string, ThunkAPIConfig> = createAsyncThunk<
    TCertificateEvent,
    string,
    ThunkAPIConfig
>("events/getEvent", async (url: string, thunkAPI): Promise<TCertificateEvent> => {
    const state = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch;
    const { control } = state;
    const { orga_slug, certificate_slug } = control.slugs;
    if (!orga_slug || !certificate_slug) return {};
    dispatch(certificatePropsApi.endpoints.getCertificateProps.initiate({ orga_slug, certificate_slug }));

    // Prevent caching for this specific request to let the data be updated if a user changes their event settings.
    const headers = { "Cache-Control": "no-cache, no-store, must-revalidate" };

    const res = await fetcher<TCertificateEvent>(url, { headers }, (err) =>
        dispatch(
            redirectActionService.doRedirect({
                reason: err.response?.status === 429 ? "too_many_requests" : "no_data",
                noNotification: err.response?.status !== 429,
            })
        )
    );

    if (!res) {
        return {};
    }

    const cleanedPreviewUrl = res?.event_badge_preview_url?.includes(".png#/")
        ? res?.event_badge_preview_url?.split(".png#/")[0]
        : res?.event_badge_preview_url?.replace(".png", "");

    const thumbnailUrl = await fetch(`${cleanedPreviewUrl}-thumbnail.png`).then((response) => {
        return response.status === 200 ? `${cleanedPreviewUrl}-thumbnail.png` : `${cleanedPreviewUrl}.png`;
    });

    const previewBaseString = await urlToBase64(`${cleanedPreviewUrl}.png`);
    const thumbnailBaseString = await urlToBase64(thumbnailUrl);

    const cleanedResponse: TCertificateEvent = {
        ...res,
        event_badge_preview_url: previewBaseString,
        event_badge_preview_thumbnail_url: thumbnailBaseString,
    };

    return cleanedResponse || {};
});

const eventActionService = {
    getEvent,
    getEventDetails,
};

export default eventActionService;
